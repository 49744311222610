// src/components/Hero.tsx
import React from 'react';
import Box from '@mui/material/Box';
import DataTransferAnimation from './DataTransferAnimation';
import '../styles/Hero.scss';

const Hero: React.FC = () => {
  return (
    <Box className="hero-container" id="home">
      <DataTransferAnimation />
    </Box>
  );
};

export default Hero;

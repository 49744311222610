// LanguageContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';

type LanguageContextType = {
  language: 'en' | 'de';
  setLanguage: React.Dispatch<React.SetStateAction<'en' | 'de'>>;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<'en' | 'de'>(() => {
    // Check if a language is already stored in localStorage
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage === 'en' || storedLanguage === 'de') {
      return storedLanguage;
    }
    
    // Determine initial language based on the domain
    const hostname = window.location.hostname;
    if (hostname.includes('iucon.de')) {
      return 'de';
    } else if (hostname.includes('iucon.com') || hostname.includes('iucon.eu')) {
      return 'en';
    }
    
    return 'de'; // Default to German if no match
  });

  useEffect(() => {
    // Store the language in localStorage whenever it changes
    localStorage.setItem('language', language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

// src/data/textContentEN.ts
import { PageSlides } from "../types/slideData";

// Die Daten für die Slides auf verschiedenen Seiten
export const textContentEN: Record<string, PageSlides> = {
  // Slides for the Homepage
  home: {
    partnershipSlide: {
      icon: "Handshake",
      title: "A Partnership That Sets Standards",
      paragraphs: [
        { text: "At iucon, we are more than just a software service provider – we are SIP's strategic partner for customized solutions. Since the founding of SIP Scootershop GmbH, we have worked hand in hand to overcome challenges and achieve goals. Our commitment is to excellent service and continuous support for SIP." },
        { text: "From comprehensive process analysis and targeted improvements to digitalization – we offer holistic support for SIP's back office. With our expertise, we bring efficiency and innovation directly into the business processes of our key account." },
        { text: "Our mission is clear: We strive to continuously optimize and simplify the daily work of SIP's employees and customers. Together, we shape the future of all business processes!" }
      ]
    },
    sipSlide: {
      icon: "TwoWheeler",
      title: "SIP Scootershop",
      paragraphs: [
        { text: "SIP Scootershop is the leading supplier of spare parts, tuning, and accessories for scooters and has been inspiring Vespa enthusiasts worldwide for over 25 years. From the beginning, iucon has been SIP's reliable partner. We developed the ERP system iuBIZ specifically and have continuously improved it to meet increasing demands." },
        { image: "/img/SIPScootershopWebsite.webp", link: "https://www.sip-scootershop.com/en" },
        { text: "Our long-standing partnership has led to optimized processes and an outstanding customer experience that has made SIP Scootershop the first choice for scooter riders." },
        { link: "https://www.sip-scootershop.com/en/page/about-sip-scootershop_23", text: "Learn more about SIP Scootershop!" }
      ]
    },
    servicesSlide: {
      icon: "ManageAccounts",
      title: "Our Services",
      paragraphs: [
        { text: "We take care of the reliable operation, maintenance, and support of the systems at SIP. Our dedicated team ensures everything runs smoothly and is always available to quickly resolve issues and ensure uninterrupted use." },
        { text: "Through detailed analysis of SIP's business processes, we identify areas for improvement and implement targeted optimizations. Our goal is to maximize efficiency and help SIP achieve top performance in all areas." },
        { text: "We drive the digitalization and automation of key processes to simplify routine tasks and make the daily work of SIP's employees easier. This prepares SIP for the challenges of the future." },
        { text: "With an agile approach and close communication, we offer tailored consulting services and quick adjustments to SIP's needs. Our flexible working style allows us to proactively respond to changes and always deliver the best solutions." }
      ]
    },
    projectsSlide: {
      icon: "AccountTree",
      title: "Continuous Improvement",
      paragraphs: [
        { text: "We precisely identify optimization potential in existing workflows, analyze their causes, and develop tailored strategies to increase efficiency, quality, and sustainability. Our goal is to take processes to the next level through innovative approaches and targeted measures, thereby securing long-term competitive advantages." },
        { text: "We promote close collaboration with all stakeholders to achieve optimal results through transparent communication and shared goal understanding. This creates a trusting partnership for sustainable success." },
        { text: "Our agile working method allows us to flexibly respond to changes and implement projects in small, iterative steps. This ensures faster adjustments and tailored solutions." },
        { text: "We focus on continuous improvement by regularly questioning and optimizing processes. This increases efficiency and quality, ensuring we are always prepared for future requirements." },
        { link: "/projects", text: "Discover our Success Stories!" }
      ]
    },
    valuesSlide: {
      icon: "Star",
      title: "What Defines Us?",
      paragraphs: [
        { text: "We take care of the reliable operation, maintenance, and support of the systems at SIP. Our dedicated team ensures everything runs smoothly and is always available to quickly resolve issues and ensure uninterrupted use." },
        { text: "Through detailed analysis of SIP's business processes, we specifically identify areas for improvement, develop customized solutions, and efficiently implement optimizations. Our goal is to sustainably increase performance and secure competitive advantages." },
        { text: "We drive the digitalization and automation of key processes to simplify routine tasks and make the daily work of SIP's employees easier. This prepares SIP for the challenges of the future." },
        { text: "With an agile approach and close communication, we offer individual consulting services and quick adjustments to SIP's needs. Our flexible working style allows us to proactively respond to changes and always deliver the best solutions." }
      ]
    },
    team1Slide: {
      icon: "Group",
      title: "Achieving Success Together",
      paragraphs: [
        { text: "Our team at iucon is more than just a group of professionals – we are a well-established unit that has grown over the years. Many of us have known each other for a long time and work closely together, which allows for deep understanding and exceptional collaboration. Each of us brings our own expertise and experience, enabling us to operate at a high level and develop innovative solutions." },
        { image: "/img/team1.webp" },
        { text: "Our long-standing experience and the trust we have built within our team are the foundation of our success. Together, we develop software that is precisely tailored to the needs of our clients and provides real added value. Quality and innovation are our priorities, enabling us to realize sustainable optimizations and maximize benefits for our clients in the long term." }
      ]
    },
    team2Slide: {
      icon: "GroupAdd",
      title: "Our Team",
      paragraphs: [
        { text: "Our passion for innovation is supported by a strong community that we actively nurture. Regular team events, from teambuilding workshops to cooking together, where we prepare lunch, strengthen our cohesion and simultaneously foster creativity and team spirit. These shared experiences create a trusting environment that forms the basis for productive collaboration." },
        { image: "/img/team2.webp" },
        { text: "At iucon, we offer an inspiring work environment at the TZU Oberhausen, specifically designed for effective work and creative collaboration. Our office includes flexible workspaces for teamwork as well as a room for meetings and workshops. This environment fosters an open and supportive work climate where our dedicated team works with passion and professionalism to achieve exceptional results and develop innovative solutions." }
      ]
    }
  },
  // Slides for the "Projects" page
  projects: {    
    projects: {
      icon: "AccountTree",
      title: "Projects at iucon: SIP's Engine",
      paragraphs: [
        { text: "Our long-standing partnership with SIP, which has existed since its inception, has given us comprehensive expertise and a deep understanding of specific requirements and challenges. In addition to software development, we offer comprehensive consulting and support in all relevant processes." },
        { image: "/img/iuBIZ.webp" },
        { text: "With extensive expertise, we develop precisely tailored solutions for various requirements. We offer comprehensive consulting for optimizing business processes and the efficient use of software. With our experience in process optimization and the implementation of interfaces, we ensure smooth and effective operations." },
        { text: "Thanks to our deep knowledge in ERP and commerce, we can provide customized solutions for individual challenges. We support the improvement of business processes and the optimal use of systems. Through targeted consulting and the implementation of effective interfaces, we ensure high efficiency and seamless integration of logistical processes." }
      ]
    },
    quote: {
      icon: "RecordVoiceOver",
      title: "Customer Testimonial",
      paragraphs: [
        { 
          "text": "<blockquote>With iucon, we have an expert at our side who shares our vision and actively supports us in its implementation. Their commitment and expertise make them an indispensable part of our success. <span>Ralf Jodl, Managing Director SIP Scootershop</span></blockquote>" 
        }
      ]
    },
    iuBIZ: {
      icon: "Wysiwyg",
      title: "iuBIZ: The ERP System from iucon for SIP",
      paragraphs: [
        { text: "iuBIZ is our ERP system that we developed with and for SIP to meet their specific requirements in logistics and business processes. With iuBIZ, we offer a customized solution that can be seamlessly integrated into existing systems and provides comprehensive functions for process optimization." },
        { text: "From materials management to financial accounting to customer management – all relevant data streams and operational processes are orchestrated through comprehensive interfaces. This ensures seamless transparency and real-time control, which forms the basis for an efficient supply chain and optimized intralogistics." },
        { text: "With the automation of routine tasks and the centralization of inventory data, iuBIZ enables precise control of goods movements and the shipping process in online retail. Our system ensures efficient inventory management and optimizes order processing for smooth and fast shipping." },
        { text: "At iucon, we focus on continuous development to keep iuBIZ always up to date with the latest requirements of online retail. Regular updates and improvements ensure that our system remains current and that goods movements and shipping processes are efficient and future-proof." }
      ]
    },
    wms: {
      icon: "Webhook",
      title: "Warehouse Management System",
      paragraphs: [
        { text: "The warehouse management system (WMS) in iuBIZ optimizes the flow of goods at SIP through seamless integration into our ERP system. It controls all warehouse processes – from goods receipt booking to inventory management to picking and shipping. With a modular approach and flexible architecture, the WMS adapts to SIP's specific requirements, ensuring efficient and transparent warehouse management." },
        { text: "Thanks to comprehensive automation, the WMS reduces errors and increases efficiency throughout the logistics area. From optimized order processing to intelligent control of warehouse movements, the system enables quick responses to market changes and seasonal fluctuations. This keeps SIP on course at all times." },
        { text: "Additionally, our WMS offers comprehensive reporting and business intelligence functions. Real-time analyses and specially tailored reports provide a clear view of all warehouse processes and enable data-driven decisions." },
        { text: "With our WMS, SIP benefits from maximum transparency, error minimization, and increased efficiency in all logistics processes – from incoming goods to shipping." }
      ]
    },
    erpfacts: {
      icon: "FactCheck",
      title: "iuBIZ Facts",
      paragraphs: [
        { text: "<b>Optimized Goods Flow and Efficient Integration with Service Providers:</b> Our solution ensures an efficient and error-free goods flow at SIP through seamless integration in both inbound and outbound processes, as well as precise inventory control. This is supported by direct connections to leading shipping and payment service providers, enabling quick and reliable order processing while avoiding stockouts and overstocking." },
        { image: "img/SIPLager1.webp" },
        { text: "<b>Transparent Reports and BI Functions:</b> With our powerful reporting and business intelligence functions, SIP gains comprehensive real-time insights into all business processes. This transparency enables informed decision-making and supports continuous process optimization." },
        { text: "<b>Future-Proof Flexibility:</b> Our solution offers flexible adjustments to meet SIP's growing needs. Whether it's about warehouse expansions or process adjustments, the system remains up-to-date and provides a future-proof solution." }
      ]
    },
    wapipod: {
      icon: "Smartphone",
      title: "WAPiPod",
      paragraphs: [
        { text: "The WAPiPOD app, used at SIP on Zebra devices, enables digital handling of central warehouse processes. From picking to storing and inventory corrections, the app supports daily workflows and reduces sources of errors." },
        { image: "img/logistiksipscootershop-2.webp" },
        { text: "<b>Paperless Picking:</b> Employees at SIP use the WAPiPOD app to efficiently and paperlessly pick items. The app guides them step by step through the process, ensuring that all orders are processed quickly and accurately." },
        { text: "<b>Storing and Returns Processing:</b> The WAPiPOD app supports the entire storing process, including returns. Through seamless integration with the ERP system, inventory is automatically updated, increasing inventory accuracy." },
        { text: "<b>Inventory Corrections and Reallocations:</b> Errors in inventory are quickly corrected by the WAPiPOD app, and reallocations can be efficiently and traceably carried out. This contributes to better visibility and control of warehouse processes." }
      ]
    },
    conveyor: {
      icon: "RepeatOneOn",
      title: "Intelligent Conveyor Control",
      paragraphs: [
        { text: "Our conveyor control solution for SIP improves the goods flow in the warehouse and avoids downtime. The software controls the conveyor system in real-time, minimizes delays, and ensures even distribution of goods. By connecting with the WAPiPOD app and packing stations, the software enables efficient networking of logistics processes." },
        { text: "<b>Intelligent Switch Control:</b> Our software ensures that each W- and T-box is precisely directed to the correct packing location. Targeted control determines whether a switch is activated." },
        { text: "<b>Dynamically Captured Codes:</b> Modern optical scanners quickly and reliably capture boxes on the conveyor loop. This information is recorded in our database to monitor and control the flow of goods accurately." },
        { image: "/img/conveyor.webp" },
        { text: "<b>Optimized Picking Order:</b> The software enhances the order of picking to complete orders more efficiently and accelerate the packing process. This results in faster and more accurate order processing, increasing customer satisfaction through timely deliveries." }
      ]
    },
    calltoaction: {
      icon: "AddReaction",
      title: "Ready to Optimize Logistics with Us?",
      paragraphs: [
        { text: "We develop customized ERP and WMS software solutions that enhance logistics processes and increase the efficiency of goods movements. Interested in learning more about our projects?" },
        { text: "Our team relies on agile methods and close collaboration with SIP to create tailored, value-driven solutions. Interested in a collaborative working approach? Join us!" },
        { text: "Do you have experience with ERP systems and agile software development, as well as knowledge in .NET, C#, and OOP? Use your expertise to develop innovative solutions and optimize logistics processes. Apply and help shape our success together." },
        { link: "/recruiting", text: "Check out our recruiting page!" }
      ]
    }
  },
  // Slides for the "Recruiting" page
  recruiting: {
    joinus: {
      icon: "AddReaction",
      title: "Join Our Team at iucon",
      paragraphs: [
        { text: "<b>ERP Software That Impresses:</b> Work on the development and optimization of our custom ERP and WMS solutions iuBIZ that make the logistics process more efficient. At iucon, you contribute to developing software that provides real value to our clients." },
        { text: "<b>Agile Work for Maximum Flexibility:</b> Use agile methods to work flexibly and purposefully in a dynamic environment. At iucon, you benefit from a culture that fosters continuous improvement and quick adaptation to new challenges." },
        { text: "<b>Teamwork That Connects:</b> Become part of a dedicated and collaborative team that works together on solutions and celebrates successes. Our open team culture ensures that your ideas are heard and successfully implemented." },
        { text: "<b>Communication as the Key to Success:</b> Experience how clear and transparent communication paves the way for successful projects. At iucon, we value regular exchanges to achieve the best results together." },
        { link: "mailto:recruiting@iucon.com", text: "Does this sound like your next career challenge? Then take the next step and apply today! We look forward to getting to know you and succeeding together." },
      ]
    },
    qualifications: {
      icon: "Stars",
      title: "What Strengths Do You Bring?",
      paragraphs: [
        { text: "<b>Experience in Software Development:</b> You have in-depth knowledge of relevant programming languages and technologies that help you develop and optimize complex software solutions." },
        { text: "<b>Problem-Solving Skills:</b> You have the ability to creatively and effectively solve complex technical challenges. Your analytical thinking and innovative approach help you find efficient and practical solutions." },
        { text: "<b>Passion for Continuous Learning:</b> You are motivated to continuously educate yourself and keep up with new technologies and trends in software development." },
        { text: "<b>Agile Work Approach:</b> With experience in agile methods and working in cross-functional teams, you can respond flexibly to changes and work effectively within the team." },
        { text: "<b>Communication Skills:</b> You have excellent communication skills in German and/or English, which help you convey clear and precise information. This strength supports teamwork and effective communication with clients and partners." },
        { link: "mailto:recruiting@iucon.com", text: "Excited about new challenges? We look forward to hearing from you and working together on innovative solutions." },
      ]
    },
    offers: {
      icon: "Loyalty",
      title: "What Do We Offer You?",
      paragraphs: [
        { text: "<b>Exciting Projects:</b> Work on challenging and innovative projects that offer you the opportunity to further develop your skills and utilize new technologies." },
        { text: "<b>Flexible Working Hours:</b> Use our flexible working hours model to adjust your work schedule to your personal needs. With us, you can design your work-life balance according to your preferences." },
        { text: "<b>Full Remote for Optimal Freedom:</b> Work from anywhere – whether from home or on the go. Our full-remote model offers you the freedom to choose your work environment and remain productive and effective." },
        { text: "<b>Professional Development Opportunities:</b> Benefit from extensive training opportunities to continuously expand your professional skills and knowledge. We support your personal and professional development with tailored training and courses." },
        { text: "<b>Secure Job for the Future:</b> Our company has been established for over 20 years and has successfully established itself during this time. We offer you a stable and future-proof job in a company that continuously grows and adapts to changing market conditions." },
        { link: "mailto:recruiting@iucon.com", text: "If you are ready to work on exciting projects, we look forward to receiving your application. Seize the opportunity and become part of our dedicated team!" },
      ]
    }
  },
  // Slides for the "SIP" page
  sip: {
    sipHistory: {
      icon: "TwoWheeler",
      title: "Origins and Development",
      paragraphs: [
        { image: "/img/siplogo.webp", link: "https://www.sip-scootershop.com/en" },
        { text: "SIP Scootershop is headquartered in Landsberg am Lech, Germany. The company was founded in 1994 by Alex Barth and Ralf Jodl, two enthusiastic Vespa riders who turned their passion for scooters and spare parts into a successful business. The name SIP stands for ‘Scooter Innovation Parts’, reflecting the founders' vision of offering innovative and high-quality parts for scooter enthusiasts." },
        { text: "The idea for SIP Scootershop arose out of necessity: Alex and Ralf realized that there were very few reliable sources for high-quality scooter spare parts at that time. They initially started importing and selling parts for their own use. What began as a small hobby project quickly evolved into a thriving business." },
      ]
    },
    quote: {
      icon: "RecordVoiceOver",
      title: "Customer Testimonial",
      paragraphs: [
        { 
          "text": "<blockquote>iucon has established itself as one of our most reliable partners. Together, we have overcome many challenges and developed solutions that have significantly simplified our workflows. With their continuous support, we can drive our progress forward and ensure our long-term competitiveness. <span>Alex Barth, Managing Director SIP Scootershop</span></blockquote>" 
        }
      ]
    },
    sipToday: {
      icon: "TwoWheeler",
      title: "SIP Today",
      paragraphs: [
        { image: "/img/SIPScootershopLocation.webp", link: "https://www.sip-scootershop.com/en" },
        { text: "Today, SIP is one of the leading providers of scooter accessories, tuning, and spare parts worldwide. The company offers over 60,000 different parts for nearly all vehicle models of major brands such as PIAGGIO, VESPA, LAMBRETTA. With over 2,000 packages shipped daily and the trust of more than 500,000 customers from over 70 countries, SIP Scootershop demonstrates its market leadership." },
      ]
    },
    sipOffers: {
      icon: "TwoWheeler",
      title: "Products and Services",
      paragraphs: [  
        { text: "<b>Spare Parts and Accessories:</b> The extensive range includes everything from engines and exhaust systems to body parts and electronics, as well as helmets, clothing, and tuning parts to meet all the needs of scooter riders."},
        { text: "<b>Tuning and Performance:</b> SIP offers special tuning parts and performance upgrades for scooters, allowing customers to get the most out of their vehicles."},
        { text: "<b>Workshop and Flagship Store:</b> In addition to its own workshop in Landsberg for repairs and maintenance, the flagship store includes an ‘experience world’ for products, advice, and a cozy SIPERIA for food and drinks, inviting visitors to linger."},
        { image: "/img/SIPLaden.webp", link: "https://www.sip-scootershop.com/en" },
      ]
    }
    // Additional slides for the "SIP" page can be added here
  }
};
// src/components/Navbar.tsx
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Diversity1Sharp';
import ProjectIcon from '@mui/icons-material/AccountTree';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext';
import '../styles/Navbar.scss';

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Mobile View width <= 900px
  const location = useLocation();
  const navigate = useNavigate(); // useNavigate Hook zum Programmieren der Navigation

  const { language, setLanguage } = useLanguage();

  const currentPath = location.pathname;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLanguageToggle = () => {
    setLanguage(language === 'de' ? 'en' : 'de');
  };

  // Funktion zum Scrollen nach oben und zur Route navigieren
  const handleScrollAndNavigate = (href: string) => {
    // Temporäres Element erstellen und zum Dokument hinzufügen
    const topElem = document.getElementsByClassName('firstSlide')[0];

    // Scrollen zum temporären Element
    topElem?.scrollIntoView({ behavior: 'auto', block: 'start' });
    
    // Nach einer kurzen Verzögerung navigieren
    setTimeout(() => {
      setDrawerOpen(false);
      navigate(href); // Navigiere zur angegebenen Route nach dem Scrollen
    }, 0); // Timeout sollte länger als die Dauer der Scroll-Animation sein
  };

  const getNavItems = () => {
    return [
      { text: 'Home', href: '/', icon: <HomeIcon />, testId: 'home-link' },
      { text: language === 'en' ? 'Projects' : 'Projekte', href: '/projects', icon: <ProjectIcon />, testId: 'projects-link' },
      { text: language === 'en' ? 'Career' : 'Karriere', href: '/recruiting', icon: <WorkIcon />, testId: 'recruiting-link' },
      { text: 'SIP Scootershop', href: '/sip-scootershop', icon: <TwoWheelerIcon />, testId: 'sip-link' }
    ];
  };

  const navItems = getNavItems();

  return (
    <AppBar position="sticky" className="navbar-appbar">
      <Toolbar data-testid="navbar">
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={handleDrawerToggle}
              className="navbar-icon-button"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: 'navbar-drawer-paper' }}
            >
              <List>
                {navItems.map((item) => (
                  <ListItem
                    button
                    component="a" // Ändere dies von Link zu "a", um das Click-Event zu ermöglichen
                    key={item.text}
                    onClick={() => handleScrollAndNavigate(item.href)}
                    data-testid={item.testId} // Hinzugefügt für Tests
                  >
                    <span className={`navbar-drawer-icon ${item.text.replace(/\s+/g, '').toLowerCase()}`}>
                      {item.icon}
                    </span>                    
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
                <ListItem button onClick={handleLanguageToggle}>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1" style={{ fontWeight: language === 'de' ? 'bold' : 'normal' }}>
                          DE
                        </Typography>
                        <Typography variant="body1" style={{ margin: '0 8px' }}>|</Typography>
                        <Typography variant="body1" style={{ fontWeight: language === 'en' ? 'bold' : 'normal' }}>
                          EN
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </Drawer>
            <a
              href={currentPath}
              onClick={(e) => {
                e.preventDefault(); // Verhindert das Standard-Verhalten des Links
                handleScrollAndNavigate(currentPath);
              }}
              className="navbar-logo"
              style={{
                width: '100%',
                textAlign: 'right',
                fontWeight: 'bold',
                color: 'white',            // Weißer Text
                textDecoration: 'none'    // Kein Unterstrich
              }}
            >
              iucon GmbH
            </a>
          </>
        ) : (
          <Box className="navbar-desktop">
            {navItems.map((item) => (
              <Button
                key={item.text}
                startIcon={item.icon}
                className="navbar-button"
                data-testid={item.testId}
                onClick={() => handleScrollAndNavigate(item.href)}
              >
                {item.text}
              </Button>
            ))}
            <Button
              onClick={handleLanguageToggle}
              className="navbar-button"
              style={{ fontWeight: 'bold' }}
            >
              {language === 'de' ? (
                <>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>DE</Typography>&nbsp;|&nbsp;
                  <Typography variant="body1">EN</Typography>
                </>
              ) : (
                <>
                  <Typography variant="body1">DE</Typography>&nbsp;|&nbsp;
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>EN</Typography>
                </>
              )}
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

// src/data/textContentDE.ts
import { PageSlides } from "../types/slideData";

// Die Daten für die Slides auf verschiedenen Seiten
export const textContentDE: Record<string, PageSlides> = {
  // Slides für die Startseite
  home: {
    partnershipSlide: {
      icon: "Handshake",
      title: "Partnerschaft, die Maßstäbe setzt",
      paragraphs: [
        { text: "Bei iucon sind wir mehr als nur ein Softwaredienstleister – wir sind der strategische Partner von SIP für kundenspezifische Lösungen. Seit der Gründung der SIP Scootershop GmbH arbeiten wir Hand in Hand, um Herausforderungen zu meistern und Ziele zu erreichen. Unser Engagement gilt der exzellenten Betreuung und kontinuierlichen Unterstützung von SIP." },
        { text: "Von umfassender Prozessanalyse und gezielter Verbesserung bis hin zur Digitalisierung – wir bieten eine ganzheitliche Betreuung für das Backoffice von SIP. Mit unserem Know-how bringen wir Effizienz und Innovation direkt in die Geschäftsprozesse unseres Key-Accounts." },
        { text: "Unsere Mission ist klar: Wir streben danach, den Arbeitsalltag der Mitarbeiter und Kunden von SIP kontinuierlich zu optimieren und zu vereinfachen. Gemeinsam gestalten wir die Zukunft aller Geschäftsprozesse!" }
      ]
    },
    sipSlide: {
      icon: "TwoWheeler",
      title: "SIP Scootershop",
      paragraphs: [
        { text: "SIP Scootershop ist der führende Anbieter für Ersatzteile, Tuning und Zubehör für Motorroller und begeistert seit über 25 Jahren Vespa-Liebhaber weltweit. Von Beginn an ist iucon der zuverlässige Partner von SIP. Wir haben das ERP-System iuBIZ individuell entwickelt und kontinuierlich weiter verbessert, um den steigenden Anforderungen gerecht zu werden." },
        { image: "/img/SIPScootershopWebsite.webp", link: "https://www.sip-scootershop.com/de" },
        { text: "Unsere langjährige Partnerschaft hat zu optimierten Prozessen und einem herausragenden Kundenerlebnis geführt, das SIP Scootershop zur ersten Adresse für Rollerfahrer gemacht hat." },
        { link: "https://www.sip-scootershop.com/de/page/uber-sip-scootershop_23", text: "Erfahre mehr über SIP Scootershop!" }
      ]
    },
    servicesSlide: {
      icon: "ManageAccounts",
      title: "Unsere Leistungen",
      paragraphs: [
        { text: "Wir übernehmen den zuverlässigen Betrieb, die Wartung und den Support der Systeme bei SIP. Unser engagiertes Team stellt sicher, dass alles reibungslos läuft und steht jederzeit zur Verfügung, um Probleme schnell zu beheben und eine unterbrechungsfreie Nutzung zu gewährleisten." },
        { text: "Durch detaillierte Analyse der Geschäftsprozesse bei SIP identifizieren wir Verbesserungspotenziale und setzen gezielte Optimierungen um. Unser Ziel ist es, die Effizienz zu maximieren und SIP in allen Bereichen zur Spitzenleistung zu verhelfen." },
        { text: "Wir treiben die Digitalisierung und Automatisierung zentraler Prozesse voran, um Routineaufgaben zu vereinfachen und den Arbeitsalltag der Mitarbeiter bei SIP zu erleichtern. So machen wir SIP fit für die Herausforderungen der Zukunft." },
        { text: "Mit einem agilen Ansatz und engem Austausch bieten wir Beratungsdienste nach Maß und schnelle Anpassungen an die Bedürfnisse von SIP. Unsere flexible Arbeitsweise ermöglicht es uns, proaktiv auf Veränderungen zu reagieren und stets die besten Lösungen zu liefern." }
      ]
    },
    projectsSlide: {
      icon: "AccountTree",
      title: "Stetige Verbesserung",
      paragraphs: [
        { text: "Wir identifizieren präzise Optimierungspotenziale in den bestehenden Workflows, analysieren deren Ursachen und entwickeln zugeschnittene Strategien, um Effizienz, Qualität und Nachhaltigkeit zu steigern. Unser Ziel ist es, durch innovative Ansätze und gezielte Maßnahmen die Prozesse auf das nächste Level zu heben und somit langfristig Wettbewerbsvorteile zu sichern." },
        { text: "Wir fördern eine enge Zusammenarbeit mit allen Stakeholdern, um durch transparente Kommunikation und gemeinsames Zielverständnis optimale Ergebnisse zu erzielen. So schaffen wir eine vertrauensvolle Partnerschaft für nachhaltigen Erfolg." },
        { text: "Unsere agile Arbeitsweise ermöglicht es, flexibel auf Änderungen zu reagieren und Projekte in kleinen, iterativen Schritten umzusetzen. So gewährleisten wir schnellere Anpassungen und passgenaue Lösungen." },
        { text: "Wir setzen auf kontinuierliche Verbesserung, indem wir Prozesse regelmäßig hinterfragen und optimieren. So steigern wir Effizienz und Qualität, um stets auf zukünftige Anforderungen vorbereitet zu sein." },
        { link: "/projects", text: "Entdecke unsere Success Stories..." }
      ]
    },
    valuesSlide: {
      icon: "Star",
      title: "Was macht uns aus?",
      paragraphs: [
        { text: "Wir übernehmen den zuverlässigen Betrieb, die Wartung und den Support der Systeme bei SIP. Unser engagiertes Team stellt sicher, dass alles reibungslos läuft und steht jederzeit zur Verfügung, um Probleme schnell zu beheben und eine unterbrechungsfreie Nutzung zu gewährleisten." },
        { text: "Durch detaillierte Analyse der Geschäftsprozesse bei SIP identifizieren wir gezielt Verbesserungspotenziale, entwickeln maßgeschneiderte Lösungen und setzen Optimierungen effizient um. Unser Ziel ist es, die Performance nachhaltig zu steigern und Wettbewerbsvorteile zu sichern." },
        { text: "Wir treiben die Digitalisierung und Automatisierung zentraler Prozesse voran, um Routineaufgaben zu vereinfachen und den Arbeitsalltag der Mitarbeiter bei SIP zu erleichtern. So machen wir SIP fit für die Herausforderungen der Zukunft." },
        { text: "Mit einem agilen Ansatz und engem Austausch bieten wir individuelle Beratungsdienste und schnelle Anpassungen an die Bedürfnisse von SIP. Unsere flexible Arbeitsweise ermöglicht es uns, proaktiv auf Veränderungen zu reagieren und stets die besten Lösungen zu liefern." }
      ]
    },
    team1Slide: {
      icon: "Group",
      title: "Gemeinsam zu Erfolgen",
      paragraphs: [
        { text: "Unser Team bei iucon ist mehr als nur eine Gruppe von Fachleuten – wir sind eine eingespielte Einheit, die über Jahre hinweg gewachsen ist. Viele von uns kennen sich schon lange und arbeiten intensiv zusammen, was ein tiefes Verständnis und eine außergewöhnliche Zusammenarbeit ermöglicht. Jeder von uns bringt seine eigene Expertise und Erfahrung mit, was es uns erlaubt, auf hohem Niveau zu agieren und innovative Lösungen zu entwickeln." },
        { image: "/img/team1.webp" },
        { text: "Unsere langjährige Erfahrung und das gewachsene Vertrauen in unserem Team bilden die Grundlage für unseren Erfolg. Gemeinsam entwickeln wir Software, die genau auf die Bedürfnisse unserer Kunden zugeschnitten sind und echten Mehrwert bieten. Dabei stehen Qualität und Innovation im Vordergrund, um nachhaltige Optimierungen zu realisieren und den Nutzen für unsere Kunden langfristig zu maximieren." }
      ]
    },
    team2Slide: {
      icon: "GroupAdd",
      title: "Unser Team",
      paragraphs: [
        { text: "Unsere Leidenschaft für Innovation wird durch eine starke Gemeinschaft gestützt, die wir aktiv pflegen. Regelmäßige Team-Events, von Teambuilding-Workshops bis hin zu gemeinsamen Kochaktionen, bei denen wir zusammen Mittagessen zubereiten, stärken unseren Zusammenhalt und fördern gleichzeitig Kreativität und Teamgeist. Diese gemeinsamen Erlebnisse schaffen ein vertrauensvolles Umfeld, das die Basis für eine produktive Zusammenarbeit bildet." },
        { image: "/img/team2.webp" },
        { text: "Bei iucon bieten wir eine inspirierende Arbeitsumgebung im TZU Oberhausen, die speziell für effektives Arbeiten und kreative Zusammenarbeit gestaltet ist. Unser Büro umfasst flexible Arbeitsplätze für gemeinsames Arbeiten sowie einen Raum für Meetings und Workshops. Diese Umgebung fördert ein offenes und unterstützendes Arbeitsklima, in dem unser engagiertes Team mit Leidenschaft und Professionalität zusammenarbeitet, um außergewöhnliche Ergebnisse zu erzielen und innovative Lösungen zu entwickeln." }
      ]
    }
  },
  // Slides für die Seite "Projekte"
  projects: {
    projects: {
      icon: "AccountTree",
      title: "Projekte bei iucon: Motor von SIP",
      paragraphs: [
        { text: "Unsere langjährige Partnerschaft mit SIP, die seit der Gründung besteht, hat uns umfassende Expertise und ein tiefes Verständnis für die spezifischen Anforderungen und Herausforderungen verschafft. Neben der Softwareentwicklung bieten wir umfassende Beratung und Unterstützung in allen relevanten Prozessen an." },
        { image: "/img/iuBIZ.webp" },
        { text: "Mit umfassender Expertise entwickeln wir präzise zugeschnittene Lösungen für viele unterschiedliche Anforderungen. Wir bieten umfassende Beratung zur Optimierung von Geschäftsprozessen und der effizienten Nutzung von Software. Mit unserer Erfahrung in der Prozessoptimierung und der Implementierung von Schnittstellen sorgen wir dafür, dass betriebliche Abläufe reibungslos und effektiv funktionieren." },
        { text: "Dank unserer tiefgehenden Kenntnisse in ERP und Handel können wir kundenspezifische Lösungen für individuelle Herausforderungen bieten. Wir unterstützen bei der Verbesserung der Geschäftsprozesse und der optimalen Nutzung der Systeme. Durch gezielte Beratung und die Implementierung effektiver Schnittstellen sichern wir eine hohe Effizienz und nahtlose Integration von logistischen Abläufen." }
      ]
    },
    quote: {
      icon: "RecordVoiceOver",
      title: "Kundenstimme",
      paragraphs: [
        { text: "<blockquote>Mit iucon haben wir einen Experten an unserer Seite, der unsere Vision teilt und uns tatkräftig bei deren Umsetzung unterstützt. Ihr Engagement und ihre Expertise machen sie zu einem unverzichtbaren Teil unseres Erfolgs.<span>Ralf Jodl, Geschäftsführer SIP Scootershop</span></blockquote>" },
      ]
    },    
    iuBIZ: {
      icon: "Wysiwyg",
      title: "iuBIZ: Das ERP-System von iucon für SIP",
      paragraphs: [
        { text: "iuBIZ ist unser ERP-System, das wir mit und für SIP entwickelt haben, um deren spezifische Anforderungen im Bereich Logistik und Geschäftsprozesse zu erfüllen. Mit iuBIZ bieten wir eine individuell zugeschnittene Lösung, die nahtlos in bestehende Systeme integriert werden kann und umfassende Funktionen für die Prozessoptimierung bereitstellt." },
        { text: "Von der Materialwirtschaft über die Finanzbuchhaltung bis hin zum Kundenmanagement – alle relevanten Datenströme und operativen Abläufe werden durch umfassende Schnittstellen orchestriert. Dies gewährleistet eine lückenlose Transparenz und Echtzeitkontrolle, was wiederum die Basis für eine effiziente Supply Chain und optimierte Intralogistik bildet." },
        { text: "Mit der Automatisierung von Routineaufgaben und der Zentralisierung von Bestandsdaten ermöglicht iuBIZ eine präzise Steuerung der Warenbewegungen und des Versandprozesses im Online-Handel. Unser System sorgt für eine effiziente Verwaltung von Lagerbeständen und optimiert die Abwicklung von Bestellungen, um reibungslose und schnelle Versandabläufe zu gewährleisten." },
        { text: "Wir bei iucon setzen auf kontinuierliche Weiterentwicklung, um iuBIZ stets an die neuesten Anforderungen des Online-Versandhandels anzupassen. Regelmäßige Updates und Verbesserungen sorgen dafür, dass unser System immer aktuell bleibt und Warenbewegungen sowie Versandprozesse effizient und zukunftssicher bleiben." }
      ]
    },
    wms: {
      icon: "Webhook",
      title: "Warenmanagementsystem",
      paragraphs: [
        { text: "Das Warenmanagementsystem (WMS) in iuBIZ optimiert den Warenfluss bei SIP durch eine nahtlose Integration in unser ERP-System. Es steuert alle Lagerprozesse – von der Wareneingangsbuchung über die Bestandsführung bis zur Kommissionierung und zum Versand. Mit einem modularen Ansatz und flexibler Architektur passt sich das WMS den spezifischen Anforderungen von SIP an und sorgt für eine effiziente, transparente Lagerverwaltung." },
        { text: "Dank umfassender Automatisierung reduziert das WMS Fehler und steigert die Effizienz im gesamten Logistikbereich. Von der optimierten Auftragsabwicklung bis hin zur intelligenten Steuerung der Lagerbewegungen ermöglicht das System eine schnelle Reaktion auf Marktveränderungen und saisonale Schwankungen. So bleibt SIP immer auf Kurs." },
        { text: "Zusätzlich bietet unser WMS umfassende Reporting- und Business-Intelligence-Funktionen. Echtzeit-Analysen und speziell angepasste Berichte geben jederzeit einen klaren Überblick über alle Lageraktivitäten. Diese Einblicke unterstützen strategische Entscheidungen und ermöglichen eine kontinuierliche Optimierung des Warenflusses." }
      ]
    }
    ,
    erpfacts: {
      icon: "FactCheck",
      title: "iuBIZ Facts",
      paragraphs: [
        { text: "<b>Optimierter Warenfluss und effiziente Anbindung an Dienstleister:</b> Unsere Lösung sorgt durch nahtlose Integration im Inbound- und Outbound-Prozess sowie präzise Bestandskontrolle für einen effizienten und fehlerfreien Warenfluss bei SIP. Dies wird durch die direkte Anbindung an führende Versand- und Zahlungsdienstleister unterstützt, was eine schnelle und zuverlässige Auftragsabwicklung ermöglicht und Lagerengpässe sowie Überbestände vermeidet." },
        { image: "img/SIPLager1.webp" },
        { text: "<b>Transparente Berichte und BI-Funktionen:</b> Mit unseren leistungsstarken Reporting- und Business-Intelligence-Funktionen erhält SIP umfassende Echtzeiteinblicke in alle Geschäftsprozesse. Diese Transparenz ermöglicht fundierte Entscheidungen und unterstützt eine kontinuierliche Optimierung der Abläufe." },
        { text: "<b>Zukunftssichere Flexibilität:</b> Unsere Lösung bietet flexible Anpassungen an die wachsenden Anforderungen von SIP. Egal, ob es um Lagererweiterungen oder Prozessanpassungen geht, das System bleibt stets auf dem neuesten Stand und gewährleistet eine zukunftssichere Lösung." }
      ]
    },
    wapipod: {
      icon: "Smartphone",
      title: "WAPiPod",
      paragraphs: [
        { text: "Die WAPiPOD-App, die bei SIP auf Zebra-Geräten eingesetzt wird, ermöglicht eine digitale Abwicklung zentraler Lagerprozesse. Von der Kommissionierung über das Einlagern bis hin zur Lagerkorrektur unterstützt die App die täglichen Arbeitsabläufe und reduziert Fehlerquellen." },
        { image: "img/logistiksipscootershop-2.webp" },
        { text: "<b>Belegloses Kommissionieren:</b> Mitarbeiter bei SIP nutzen die WAPiPOD-App, um Artikel effizient und papierlos zu kommissionieren. Die App führt sie Schritt für Schritt durch den Prozess und stellt sicher, dass alle Bestellungen schnell und fehlerfrei abgewickelt werden." },
        { text: "<b>Einlagern und Retourenabwicklung:</b> Die WAPiPOD-App unterstützt den kompletten Einlagerungsprozess, einschließlich der Rückführung von Waren. Durch die nahtlose Integration in das ERP-System werden Bestände automatisch aktualisiert, was die Lagergenauigkeit erhöht." },
        { text: "<b>Lagerkorrektur und Umlagerungen:</b> Fehler in Beständen werden durch die WAPiPOD-App schnell korrigiert, und Umlagerungen können effizient und nachvollziehbar durchgeführt werden. Dies trägt zu einer besseren Übersicht und Steuerung der Lagerprozesse bei." },
      ]
    },
    conveyor: {
      icon: "RepeatOneOn",
      title: "Intelligente Fördertechniksteuerung",
      paragraphs: [
        { text: "Unsere Fördertechniklösung für SIP verbessert den Warenfluss im Lager und vermeidet Ausfallzeiten. Die Software steuert in Echtzeit die Fördertechnik, minimiert Verzögerungen und sorgt für eine gleichmäßige Warenverteilung. Durch die Verbindung mit der WAPiPOD-App und den Packstationen ermöglicht die Software eine effiziente Vernetzung der Logistikprozesse." },
        { text: "<b>Intelligente Weichensteuerung:</b> Unsere Software sorgt dafür, dass jede W- und T-Box präzise an den richtigen Packplatz geleitet wird. Durch gezielte Steuerung wird entschieden, ob eine Weiche aktiviert wird." },
        { text: "<b>Dynamische Code-Erfassung:</b> Moderne optische Scanner erfassen die Boxen auf dem Loop schnell und zuverlässig. Diese Informationen werden in unserer Datenbank erfasst, um den Warenfluss genau zu überwachen und zu steuern." },
        { image: "/img/conveyor.webp" },
        { text: "<b>Optimierung der Kommissionierreihenfolge:</b> Die Software verbessert die Reihenfolge der Kommissionierung, um Bestellungen effizienter abzuschließen und den Packprozess zu beschleunigen. Dies führt zu einer schnelleren und präziseren Bearbeitung der Aufträge, was den Kundennutzen erhöht und die Zufriedenheit durch zeitnahe Lieferungen steigert." },
      ]
    },
    calltoaction: {
      icon: "AddReaction",
      title: "Bereit, mit uns Logistik zu optimieren?",
      paragraphs: [
        { text: "Wir entwickeln individuelle ERP- und WMS-Softwarelösungen, die den Logistikprozess verbessern und die Effizienz von Warenbewegungen steigern. Möchtest du mehr über unsere Projekte erfahren?"},
        { text: "Unser Team setzt auf agile Methoden und enge Zusammenarbeit mit SIP, um kundenbezogene, wertorientierte Lösungen zu schaffen. Interessiert an einer partnerschaftlichen Arbeitsweise? Komm zu uns!"},
        { text: "Hast du Erfahrung mit ERP-Systemen und agiler Softwareentwicklung, sowie Kenntnisse in .NET, C# und OOP? Setze deine Expertise ein, um innovative Lösungen zu entwickeln und Logistikprozesse zu optimieren. Bewirb dich und gestalte gemeinsam mit uns den Erfolg."},
        { link: "/recruiting", text: "Schau dir unsere Recruitingseite an!" }
      ]
    }
  },
  // Slides für die Seite "Recruiting"
  recruiting: {
    joinus: {
      icon: "AddReaction",
      title: "Werde Teil unseres Teams bei iucon",
      paragraphs: [
        { text: "<b>ERP Software, die überzeugt:</b> Arbeite an der Entwicklung und Optimierung unserer passgenauen ERP- und WMS-Lösungen iuBIZ, die den Logistikprozess effizienter gestalten. Bei iucon trägst du dazu bei, Software zu entwickeln, die echten Mehrwert für unsere Kunden bietet."},
        { text: "<b>Agiles Arbeiten für maximale Flexibilität:</b> Nutze agile Methoden, um in einem dynamischen Umfeld flexibel und zielgerichtet zu arbeiten. Bei iucon profitierst du von einer Kultur, die kontinuierliche Verbesserung und schnelle Anpassungen an neue Herausforderungen fördert."},
        { text: "<b>Teamarbeit, die verbindet:</b> Werde Teil eines engagierten und kollaborativen Teams, das gemeinsam an Lösungen arbeitet und Erfolge feiert. Unsere offene Teamkultur sorgt dafür, dass deine Ideen gehört und erfolgreich umgesetzt werden."},
        { text: "<b>Kommunikation als Schlüssel zum Erfolg:</b> Erlebe, wie klare und transparente Kommunikation den Weg zu erfolgreichen Projekten ebnet. Bei iucon legen wir großen Wert auf regelmäßigen Austausch, um gemeinsam die besten Ergebnisse zu erzielen."},
        { link: "mailto:recruiting@iucon.com", text: "Klingt das nach deiner nächsten beruflichen Herausforderung? Dann mach den nächsten Schritt und bewirb dich noch heute! Wir sind gespannt darauf, dich kennenzulernen und gemeinsam mit dir erfolgreich zu sein." },
      ]
    },
    qualifications: {
      icon: "Stars",
      title: "Welche Stärken bringst du mit?",
      paragraphs: [
        { text: "<b>Erfahrung in der Softwareentwicklung:</b> Du verfügst über fundierte Kenntnisse in relevanten Programmiersprachen und Technologien, die dir helfen, komplexe Softwarelösungen zu entwickeln und zu optimieren."},
        { text: "<b>Problemlösungsfähigkeiten:</b> Du bringst die Fähigkeit mit, komplexe technische Herausforderungen kreativ und effektiv zu lösen. Dein analytisches Denken und deine innovative Herangehensweise helfen dir, effiziente und praxisnahe Lösungen zu finden."},
        { text: "<b>Leidenschaft für kontinuierliches Lernen:</b> Du bist motiviert, dich stetig weiterzubilden und neue Technologien sowie Trends in der Softwareentwicklung zu verfolgen."},
        { text: "<b>Agile Arbeitsweise:</b> Mit Erfahrung in agilen Methoden und der Arbeit in cross-funktionalen Teams kannst du flexibel auf Veränderungen reagieren und effektiv im Team arbeiten."},
        { text: "<b>Kommunikative Kompetenz:</b> Du verfügst über ausgezeichnete Kommunikationsfähigkeiten in Deutsch und/oder Englisch, die dir helfen, klare und präzise Informationen zu vermitteln. Diese Stärke unterstützt die Zusammenarbeit im Team und die effektive Kommunikation mit Kunden und Partnern."},
        { link: "mailto:recruiting@iucon.com", text: "Hast du Lust auf neue Herausforderungen? Wir freuen uns darauf, von dir zu hören und gemeinsam an innovativen Lösungen zu arbeiten." },
      ]
    },
    offers: {
      icon: "Loyalty",
      title: "Was bieten wir dir an?",
      paragraphs: [
        { text: "<b>Spannende Projekte:</b> Arbeite an herausfordernden und innovativen Projekten, die dir die Möglichkeit bieten, deine Fähigkeiten weiterzuentwickeln und neue Technologien zu nutzen."},
        { text: "<b>Gleitzeit für deine Flexibilität:</b> Nutze unser flexibles Gleitzeitmodell, um deine Arbeitszeiten an deine persönlichen Bedürfnisse anzupassen. Bei uns kannst du deine Work-Life-Balance ganz nach deinen Vorstellungen gestalten."},
        { text: "<b>Full Remote für optimale Freiheit:</b> Arbeite von überall aus – ob von zu Hause oder unterwegs. Unser Full-Remote-Modell bietet dir die Freiheit, deine Arbeitsumgebung nach deinen Wünschen zu wählen und dabei produktiv und effektiv zu bleiben."},
        { text: "<b>Weiterbildungsmöglichkeiten für deine Entwicklung:</b> Profitiere von umfangreichen Weiterbildungsmöglichkeiten, um deine beruflichen Fähigkeiten und Kenntnisse kontinuierlich zu erweitern. Wir unterstützen deine persönliche und berufliche Entwicklung mit maßgeschneiderten Trainings und Schulungen."},
        { text: "<b>Zukunftssicherer Arbeitsplatz:</b> Unser Unternehmen besteht seit über 20 Jahren und hat sich in dieser Zeit erfolgreich etabliert. Wir bieten dir einen stabilen und zukunftssicheren Arbeitsplatz in einem Unternehmen, das kontinuierlich wächst und sich an die sich verändernden Marktbedingungen anpasst."},
        { link: "mailto:recruiting@iucon.com", text: "Wenn du bereit bist, an spannenden Projekten mitzuarbeiten, dann freuen wir uns auf deine Bewerbung. Nutze die Chance und werde Teil unseres engagierten Teams!" },
      ]
    }
    
  },
  // Slides für die Seite "SIP"
  sip: {    
    sipHistory: {
      icon: "TwoWheeler",
      title: "Anfänge und Entwicklung",
      paragraphs: [
        { image: "/img/siplogo.webp", link: "https://www.sip-scootershop.com/de/page/30-jahre-sip-scootershop_18" },
        { text: "SIP Scootershop hat seinen Firmensitz in Landsberg am Lech, Deutschland. Das Unternehmen wurde 1994 von Alex Barth und Ralf Jodl gegründet, zwei begeisterte Vespa-Fahrer, die ihre Leidenschaft für Roller und Ersatzteile in ein erfolgreiches Geschäft umwandelten. Der Name SIP steht für „Scooter Innovation Parts“, was die Vision der Gründer widerspiegelt: innovative und qualitativ hochwertige Teile für Rollerenthusiasten anzubieten." },
        { text: "Die Idee zu SIP Scootershop entstand aus der Notwendigkeit heraus: Alex und Ralf stellten fest, dass es damals kaum zuverlässige Quellen für hochwertige Roller-Ersatzteile gab. Sie begannen zunächst damit, Teile für ihren eigenen Gebrauch zu importieren und zu verkaufen. Was als kleines Hobbyprojekt begann, entwickelte sich schnell zu einem florierenden Unternehmen." },
      ]
    },
    quote: {
      icon: "RecordVoiceOver",
      title: "Kundenstimme",
      paragraphs: [
        { text: "<blockquote>iucon hat sich als einer unserer zuverlässigsten Partner etabliert. Gemeinsam haben wir viele Herausforderungen bewältigt und Lösungen entwickelt, die unsere Arbeitsabläufe erheblich vereinfacht haben. Mit ihrer kontinuierlichen Unterstützung können wir unsere Weiterentwicklung vorantreiben und unsere langfristige Wettbewerbsfähigkeit sichern.<span>Alex Barth, Geschäftsführer SIP Scootershop</span></blockquote>" },
      ]
    },
    sipToday: {
      icon: "TwoWheeler",
      title: "SIP heute",
      paragraphs: [
        { image: "/img/SIPScootershopLocation.webp", link: "https://www.sip-scootershop.com/de" },
        { text: "Heute ist SIP einer der führenden Anbieter von Motorroller-Zubehör, Tuning und Ersatzteilen weltweit. Das Unternehmen bietet über 60.000 verschiedene Teile für nahezu alle Fahrzeugmodelle großer Marken wie PIAGGIO, VESPA, LAMBRETTA an. Mit täglich über 2.000 versendeten Paketen und dem Vertrauen von mehr als 500.000 Kunden aus über 70 Ländern beweist SIP Scootershop seine Marktführerschaft." },
      ]
    },
    sipOffers: {
      icon: "TwoWheeler",
      title: "Produkte und Dienstleistungen",
      paragraphs: [  
        { text: "<b>Ersatzteile und Zubehör:</b> Das umfassende Sortiment reicht von Motoren und Auspuffanlagen über Karosserieteile und Elektronik bis hin zu Helm, Bekleidung und Tuningteilen, um alle Bedürfnisse der Rollerfahrer abzudecken."},
        { text: "<b>Tuning und Performance:</b> SIP bietet spezielle Tuningteile und Performance-Upgrades für Roller an, damit Kunden das Maximum aus ihren Fahrzeugen herausholen können."},
        { text: "<b>Werkstatt und Flagship Store:</b> Neben einer eigenen Werkstatt in Landsberg für Reparaturen und Wartungen, umfasst der Flagship Store eine „Erlebniswelt“ für Produkte, Beratung und eine gemütliche SIPERIA für Essen und Trinken, die zum Verweilen einlädt."},
        { image: "/img/SIPLaden.webp", link: "https://www.sip-scootershop.com/de" },
      ]
    }
  }
};
// src/pages/GenericPage.tsx
import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Hero from '../components/Hero';
import iconMapping from '../data/iconMapping';
import { SlideDataMap } from '../types/slideData';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../styles/GenericPage.scss';

interface GenericPageProps {
  showHero: boolean;
  slideData: SlideDataMap;
  pageTitle: string;
  dataTestId: string;
}

const GenericPage: React.FC<GenericPageProps> = ({ showHero, slideData, pageTitle, dataTestId }) => {
  // Sammle alle Bild-URLs für das Preloading
  const imageUrls = Object.values(slideData).flatMap(slide =>
    slide.paragraphs.flatMap(content => content.image ? [content.image] : [])
  );

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {imageUrls.map((imageUrl, index) => (
            <link key={index} rel="preload" as="image" href={imageUrl} />
          ))}
        </Helmet>      

      {/* Hero Section */}
      {showHero && (
        <Box sx={{ scrollSnapAlign: 'start' }} data-testid="hero-section">
          <Hero />
        </Box>
      )}

      {/* Slides */}
      {Object.keys(slideData).map((key, index) => {
        const slide = slideData[key];

        return (
          <Box
            key={index}
            sx={{
              scrollSnapAlign: 'start',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
              background: 'linear-gradient(to bottom right, #EEF0D4, #FDF1F0)',
              paddingTop: 4,
              paddingLeft: 0,
              paddingRight: 0,
              textAlign: 'center'
            }}
            data-testid={`${dataTestId}-slide-${index}`}
            className={index === 0 ? 'firstSlide' : ''}
          >
            <Paper sx={{ width: '90%', maxWidth: 1000, p: 3, bgcolor: 'rgba(255, 255, 255, 0.9)' }}>
              <Avatar sx={{ bgcolor: '#fff', color: '#333', margin: '0 auto', mb: 2 }}>
                {iconMapping[slide.icon]} {/* Icon dynamisch laden */}
              </Avatar>
              <Typography
                variant="h2"
                className='slideTitle'
                sx={{ mb: 4 }}
              >
                {slide.title}
              </Typography>
              <Grid container spacing={2}>
                {slide.paragraphs.map((content: any, idx: number) => {
                  const isFullWidth = idx === 0 && slide.paragraphs.length % 2 !== 0;
                  const isLink = Boolean(content.link);
                  const gridItemProps = {
                    xs: 12,
                    sm: isFullWidth ? 12 : 6
                  };

                  return (
                    <Grid item key={idx} {...gridItemProps}>
                      <Paper
                        sx={{
                          p: 2,
                          height: '100%',
                          display: isLink ? 'flex' : 'block',
                          alignItems: isLink ? 'center' : 'flex-start',
                          justifyContent: isLink ? 'center' : 'flex-start',
                          textAlign: isLink ? 'center' : 'justify',
                          backgroundColor: '#fff',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                        elevation={0}
                      >
                        {content.text && (
                          <Box sx={{ textAlign: isLink ? 'center' : 'justify', width: '100%' }}>
                            {isLink ? (
                              <Link
                                href={content.link}
                                target="_blank"
                                rel="noopener"
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  textDecoration: 'none',
                                  color: 'inherit'
                                }}
                                data-testid={`link-${idx}`}
                              >
                                <Typography
                                  variant="body1"
                                  className='slideContent'
                                  sx={{ mb: 1 }}
                                  dangerouslySetInnerHTML={{ __html: content.text }}
                                />
                                <OpenInNew
                                  sx={{
                                    fontSize: 30,
                                    color: '#333',
                                    transition: 'transform 0.3s',
                                    '&:hover': { transform: 'translateX(5px)' }
                                  }}
                                />
                              </Link>
                            ) : (
                              <Typography
                                variant="body1"
                                className='slideContent'
                                sx={{ mb: 1 }}
                                dangerouslySetInnerHTML={{ __html: content.text }}
                              />
                            )}
                          </Box>
                        )}
                        {content.image && (
                          <Box
                            component={content.link ? 'a' : 'div'}
                            href={content.link}
                            target={content.link ? '_blank' : undefined}
                            rel={content.link ? 'noopener noreferrer' : undefined}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                          >
                            <Helmet>
                              <link rel="preload" as="image" href={content.image} />
                            </Helmet>
                            <Box
                              component="img"
                              src={content.image}
                              alt={`Slide image ${idx}`}
                              loading="lazy"
                              sx={{ maxWidth: '100%', height: 'auto' }}
                            />
                            {content.link && (
                              <OpenInNew
                                sx={{
                                  position: 'absolute',
                                  bottom: 10,
                                  right: 10,
                                  fontSize: 30,
                                  color: '#333',
                                  transition: 'transform 0.3s',
                                  '&:hover': { transform: 'translateX(5px)' }
                                }}
                              />
                            )}
                          </Box>
                        )}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
            
          </Box>
        );
      })}
      </HelmetProvider>
    </>
  );
};

export default GenericPage;
